import React from 'react';

// External components
import { Button, Box, Heading } from '@thepuzzlers/pieces';

// Animation
import { fadeOut, topLineAnimation, bottomLineAnimation } from './animation';

export const NavButton = ({ text, handleClick, animated = false, sx }) => {
  const lineMargin = ['0.7em', '0.83em', '0.66em', '0.7em', '0.67em', '0.67em'];

  return (
    <Button
      className="navigation__menu-button"
      variant="navButton"
      onClick={handleClick}
      sx={{
        gridColumnEnd: [13, 13, 25, 25, 25, 25],
        justifySelf: 'end',
        display: [
          'inlineBlock',
          'inlineBlock',
          'inlineBlock',
          'inlineBlock',
          'none',
          'none'
        ],
        ...sx
      }}>
      <NavButtonLine
        sx={{ mb: lineMargin }}
        variants={animated && topLineAnimation}
        initial="initial"
        animate="rotate"
        exit="initial"
      />
      <Heading
        as="span"
        type="navbarLink"
        variant="bold"
        variants={animated && fadeOut}
        initial="show"
        animate="hidden"
        exit="show">
        {text}
      </Heading>
      <NavButtonLine
        sx={{ mt: lineMargin }}
        variants={animated && bottomLineAnimation}
        initial="initial"
        animate="rotate"
        exit="initial"
      />
    </Button>
  );
};

// Elemets

const NavButtonLine = ({ sx, ...props }) => (
  <Box
    as="span"
    sx={{ display: 'block', bg: 'red', height: '2px', width: '100%', ...sx }}
    {...props}
  />
);
