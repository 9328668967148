import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Close Button Animation
export const fadeOut = {
  show: {
    opacity: 1
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.2
    }
  }
};

export const topLineAnimation = {
  initial: {
    rotate: '0deg'
  },
  rotate: {
    transformOrigin: 'right center',
    rotate: '-20deg'
  }
};

export const bottomLineAnimation = {
  initial: {
    rotate: '0deg'
  },
  rotate: {
    transformOrigin: 'right center',
    rotate: '20deg'
  }
};

// Navigation bar animation

export const revealNavigation = (elements, delay) => {
  // Animation defaults
  gsap.defaults({ ease: 'Power4.easeOut' });
  const navigationBarBackground = '.navigation-bar-wrapper';

  gsap.set(elements, { autoAlpha: 0 });

  // Timeline
  let tl = gsap.timeline({ delay: delay }); // delay to match up with header animation

  tl.from(navigationBarBackground, {
    opacity: 0,
    duration: 0.3
  }).fromTo(
    elements,
    { y: 24 },
    {
      autoAlpha: 1,
      y: 0,
      duration: 1.8,
      stagger: 0.2
    }
  );

  return () => {
    tl.pause(0).kill(true);
  };
};

// Navigation Overlay animation

export const revealOverlayAnimation = (containerRef, handleClose) => {
  const elements = [...containerRef.current.children[2].children];

  const redLines = containerRef.current.querySelectorAll('.horizontal-line');
  const navLinkText = containerRef.current.querySelectorAll(
    '.nav-link__content-container'
  );
  // other elements headline, contactLinks, and languageSwitch
  const healdine = elements[1];
  const otherText = [elements[4], elements[5]];

  const timeline = gsap.timeline({
    onReverseComplete: handleClose
  });

  timeline
    .from(healdine, {
      y: '50%',
      ease: 'Power3.easeInOut',
      duration: 0.5,
      opacity: 0
    })
    .from(
      redLines,
      {
        transformOrigin: 'left center',
        scaleX: 0,
        stagger: 0.05,
        ease: 'Expo.easeOut',
        duration: 0.75
      },
      '<0.3'
    )
    .from(
      navLinkText,
      {
        yPercent: 100,
        ease: 'Power3.easeInOut',
        duration: 0.75,
        stagger: 0.1
      },
      '<0.1'
    )

    .from(
      otherText,
      {
        y: 100,
        ease: 'Power3.easeInOut',
        duration: 0.75,
        opacity: 0,
        stagger: 0.05
      },
      '<0.1'
    );

  return timeline;
};

export const onScrollNavigationBarAnimation = () => {
  /**
   * The hide on scroll animation and color change animation
   * separate into different animation because it has different scroll point to animated
   * TODO: we can update the color change animation to be use gsap to make it more cleaner
   */

  // Hide scroll animation
  const showAnimation = gsap
    .from('.navigation-bar-wrapper', {
      yPercent: -100,
      paused: true,
      duration: 0.3
    })
    .progress(1);
  ScrollTrigger.create({
    start: '120px top',
    onUpdate: (self) => {
      self.direction === -1 ? showAnimation.play() : showAnimation.reverse();
    }
  });
};
