import React from 'react';

// External Components
import {
  Overlay,
  GridWrapper,
  Heading,
  GridItem,
  Link,
  Box,
  FlexWrapper
} from '@thepuzzlers/pieces';

// Local Components
import { NavLinkListItem, ListWrapper, LanguageSwitch } from 'components';

// Self Components
import { useNavigationData } from './useNavigationData';
import { Logo, NavButton } from './components';

// Framer Motion
import { AnimatePresence } from 'framer-motion';

// Animation
import { revealOverlayAnimation } from './components/animation';

// Context
import { useNavOverlayAnimation } from 'context/NavOverlayAnimationContext';

export const NavigationOverlay = ({
  overlayControl: { openOverlay, handleClose }
}) => {
  const { name, navigationLinks, phone, email } = useNavigationData();
  const { setAnimationIsEnd } = useNavOverlayAnimation();

  // indicate that will be exist exit animation

  const handleOnExitComplete = () => {
    setAnimationIsEnd();
  };
  return (
    // Markup
    <AnimatePresence exitBeforeEnter onExitComplete={handleOnExitComplete}>
      {openOverlay && (
        <Overlay
          handleClose={handleClose}
          containerSx={{ zIndex: 12 }}
          shouldCloseOnBackdropClick
          shouldCloseOnEscapePress>
          <Content
            handleClose={handleClose}
            name={name}
            navigationLinks={navigationLinks}
            phone={phone}
            email={email}
          />
        </Overlay>
      )}
    </AnimatePresence>
  );
};

// Elements

const Content = ({ handleClose, name, navigationLinks, phone, email }) => {
  const containerRef = React.useRef(null);
  const [isOverlayOpen, setIsOverlayOpen] = React.useState(true);
  const { setAnimationIsStart } = useNavOverlayAnimation();

  const handleDelayClose = () => {
    setIsOverlayOpen(false);
  };
  React.useLayoutEffect(() => {
    // use layout effect needed to prevent splashing at the start
    const animation = revealOverlayAnimation(containerRef, handleClose);
    setAnimationIsStart();
    if (!isOverlayOpen) {
      animation.reverse(0);
    }

    return () => {
      animation.kill();
    };
  }, [isOverlayOpen]);
  return (
    <GridWrapper
      ref={containerRef}
      sx={{
        bg: 'background',
        height: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        pt: ['3.14%', '3.49%', '3.96%', '1.45%', '2.80%', '2.50%'], // This pt matches closed navigation
        gridTemplateRows: 'auto 1fr' // this makes sure the nav bar is always aligned to the top, and the content is centered
      }}>
      <Logo onClick={handleDelayClose} />
      <NavButton text={name} handleClick={handleDelayClose} animated={true} />
      <GridWrapper
        variant="inside.autoColumns"
        className="navigation-overlay"
        sx={{
          gridTemplateRows: [
            null,
            null,
            null,
            null,
            'auto auto auto 1fr',
            'auto auto auto 1fr'
          ],
          alignSelf: 'center'
        }}>
        <TopSpacer />
        <Headline text={name} />
        <NavigationLinks
          links={navigationLinks}
          handleClose={handleDelayClose}
        />
        <ContactSpacer />
        <ContactLinks phone={phone} email={email} />
        <LangSwitcher handleClose={handleDelayClose} />
        <BottomSpacer />
      </GridWrapper>
    </GridWrapper>
  );
};

const Headline = ({ text }) => {
  return (
    <Heading
      as="h2"
      type="h2-900-110"
      variant="bold"
      sx={{
        gridColumn: [
          '1/13',
          '1/13',
          '3 / span 20',
          '1 / span 12',
          '1 / span 12',
          '1 / span 12'
        ],
        textTransform: 'uppercase'
      }}>
      {text}
    </Heading>
  );
};

const NavigationLinks = ({ links, handleClose }) => {
  return (
    <ListWrapper
      animateLine={false}
      sx={{
        gridColumn: [
          '1/13',
          '1/13',
          '3 / span 20',
          '14 / 25',
          '14 / 25',
          '14 / 25'
        ],
        gridRow: [null, null, null, '2 / span 4', '2 / span 5', '2 / span 5'],
        mt: ['12.54%', '10.86%', '10.90%', 0, 0, 0]
      }}>
      {links.map((link) => (
        <NavLinkListItem
          key={link.to}
          title={link.title}
          to={link.to}
          type="h3-300-110"
          handleOverlayClose={handleClose}
        />
      ))}
    </ListWrapper>
  );
};

const ContactLinks = ({ phone, email }) => {
  return (
    <FlexWrapper
      className="navigation-overlay__contact-links"
      sx={{
        gridColumnStart: [1, 1, 3, 1, 1],
        gridColumnEnd: 'span 9',
        flexDirection: 'column',
        mt: ['43.71%', '40.84%', 0, '22.51%', 0, 0],
        gridRow: [null, null, null, null, 5, 5]
      }}>
      <Link variant="navOverlayContactLink" href={phone.to}>
        {phone.title}
      </Link>
      <Link
        variant="navOverlayContactLink"
        href={email.to}
        sx={{
          mt: ['0.62em', '0.53em', '0.8em', '0.61em', '0.5em', '0.61em']
        }}>
        {email.title}
      </Link>
    </FlexWrapper>
  );
};

const LangSwitcher = ({ handleClose }) => {
  return (
    <LanguageSwitch
      sx={{
        gridColumnStart: [1, 1, 17, 1, 1, 1],
        gridColumnEnd: 'span 6',
        gridRow: [null, null, null, null, 6, 6],
        justifySelf: [null, null, 'end', 'start', 'start', 'start'],
        alignSelf: ['end', 'end', 'end', 'start', 'start', 'start'],
        mt: ['12.74%', '10.27%', 0, '14.44%', '13.13%', '11.69%'],
        // this overddes the LangSwitcher components default styles
        display: 'flex',
        '& .switch-link': {
          visibility: 'visible'
        }
      }}
      handleOverlayClose={handleClose}
    />
  );
};

// Spacers
const TopSpacer = () => (
  <GridItem
    className="navigation-overlay__top-spacer"
    sx={{
      mt: ['7.52%', '9.31%', '16.97%', '3.78%', '5.01%', '2.74%']
    }}
  />
);
const ContactSpacer = () => (
  <GridItem
    className="navigation-overlay__contact-spacer"
    sx={{
      mt: [0, 0, '19.09%', 0, 0, 0],
      display: ['none', 'none', 'block', 'none', 'none', 'none']
    }}
  />
);
const BottomSpacer = () => (
  <GridItem
    className="navigation-overlay__bottom-spacer"
    sx={{
      // mt: ['20.69%', '21.71%', '19.23%', '8.57%', '18.22%', '17.35%']
      mt: ['20.69%', '21.71%', '19.23%', '8.57%', '10%', '9%']
    }}
  />
);
