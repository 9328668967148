export const arrowAnimation = {
  initial: {
    rotate: 0
  },
  hover: {
    rotate: '45deg'
  }
};

export const arrowTransition = {
  duration: 0.2
};
