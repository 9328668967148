import React from 'react';

// Create Context
export const AnimationContext = React.createContext();

// Hook
export const useNavOverlayAnimation = () => React.useContext(AnimationContext);

// Create ContextProvider
export const NavOverlayAnimationContextProvider = ({ children }) => {
  const [isAnimating, setIsAnimating] = React.useState(false);

  const setAnimationIsStart = () => {
    setIsAnimating(true);
  };

  const setAnimationIsEnd = () => {
    setIsAnimating(false);
  };

  return (
    <AnimationContext.Provider
      value={{
        // Animation notice State
        isAnimating,
        // Animation notice control
        setAnimationIsStart,
        setAnimationIsEnd
      }}>
      {children}
    </AnimationContext.Provider>
  );
};
