import React from 'react';

// External Components
import { NavigationLink, useLocalizePath } from 'gatsby-theme-thepuzzlers-intl';
import { Button, Box } from '@thepuzzlers/pieces';
import { navigate } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

// Local Components
import { RedArrow, BackArrow } from 'components';

export const Hyperlink = ({ text, to, sx, ...props }) => {
  return (
    <Box
      sx={{
        gridColumn: [
          '1/span 9',
          '1/span 10',
          '1/span 14',
          '1/span 11',
          '1/span 10',
          '2/span 8'
        ],
        mt: ['16.81%', '11.21%', '15.71%', '15.42%', '15.55%', '14.91%'],
        ...sx
      }}>
      <NavigationLink to={to} {...props} variant="hyperLink">
        {/** added more box to insert the framer motion animation props*/}
        <Box
          initial="initial"
          whileHover="hover"
          transition={{ duration: 0.3 }}
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
          <Arrow />
          {text}
        </Box>
      </NavigationLink>
    </Box>
  );
};

export const ButtonPrimary = ({ text, sx, ...props }) => {
  return (
    <Button
      initial="initial"
      whileHover="hover"
      {...props}
      variant="links.hyperLink"
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...sx
      }}>
      <Arrow />
      {text}
    </Button>
  );
};

export const BackButton = ({ text, sx, primary = false, ...props }) => {
  const { locale } = usePageContext();

  const pathToReturnTo = useLocalizePath('/projects/', locale);

  const navigateBack = (pathToReturnTo) => {
    const currentPath = sessionStorage.getItem('current-path');

    const languageHasBeenSwitched = sessionStorage.getItem(
      'language-has-switched-on-project-detail'
    );

    let backPage = () => navigate(-1);

    // if language has been switched we can't navigate (-1) because that would undo the language switch.
    if (languageHasBeenSwitched === 'true') {
      backPage = () => navigate(pathToReturnTo);
    }

    sessionStorage.setItem('language-has-switched-on-project-detail', false);

    // if currentPath doesn't exist it means the project detail page is our entrypoint url, which means we can't navigate(-1), it would lead us out of our website
    return !currentPath || currentPath === 'null'
      ? navigate(pathToReturnTo)
      : backPage();
  };

  return (
    <Button
      onClick={() => navigateBack(pathToReturnTo)}
      {...props}
      variant={primary ? 'back.primary' : 'back.secondary'}
      sx={{ ...sx }}>
      <BackArrow
        sx={{
          color: primary ? 'text' : 'red',
          mr: '0.6em',
          width: primary
            ? ['1.3em', '1.47em', '1.4em', '1.3em', '1.4em', '1.2em']
            : ['2.3em', '2.22em', '1.6em', '1.6em', '1.5em', '1.3em']
        }}
      />
      {text}
    </Button>
  );
};

// Reused Elements
const Arrow = () => (
  <RedArrow
    sx={{
      mr: ['1em', '1.1em', '1.09em', '0.89em', '1.09em', '1em'],
      minWidth: ['1.5em', '1.46em', '1.46em', '1.34em', '1.46em', '1em'],
      width: 'auto'
    }}
  />
);
