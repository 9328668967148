// external dependencies
import React from 'react';

// External Components
import {
  Section,
  GridItem,
  FlexWrapper,
  Heading,
  Link,
  Box,
  Paragraph
} from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import {
  ListWrapper,
  NavLinkListItem,
  MdlExpoLogo,
  ImageContainer
} from 'components';

// Assets
import { useFooterData } from './useFooterData';
import { useNavigationData } from '../Navigation/useNavigationData';

export const Footer = () => {
  const { headline, paragraph, copyright, logos } = useFooterData();
  const { navigationLinks, legal, privacy, phone, email } = useNavigationData();

  return (
    <Section as="footer" id="footer">
      <Headline text={headline} />
      <NavLinksSpcer />
      <ContactLinks phone={phone} email={email} />
      <NavigationLinks links={navigationLinks} />
      <TextBlockWithLogo text={paragraph} />
      <LegalSpacer />
      <Logos logos={logos} />
      <LegalAndPrivacy legal={legal} privacy={privacy} />
      <CopyrightText text={copyright} />
      <BottomSpacer />
    </Section>
  );
};

const Headline = ({ text }) => {
  return (
    <Heading
      as="h2"
      type="h2-900-110"
      className="footer__headline"
      variant="bold"
      sx={{
        gridColumn: [
          '1/13',
          '1/ span 11',
          '1 / span 22',
          '13 / 25',
          '13 / 25',
          '12 / span 12'
        ],
        textTransform: 'uppercase'
      }}>
      {text}
    </Heading>
  );
};

const ContactLinks = ({ phone, email }) => {
  return (
    <Box
      className="footer__contacts"
      sx={{
        gridColumn: [
          '1/13',
          '1/13',
          '1/25',
          '1 / span 10',
          '1 / span 10',
          '2 / span 8'
        ],
        display: 'flex',
        flexDirection: 'column',
        mt: ['12.54%', '7.76%', '6.79%', 0, 0, 0]
      }}>
      <Link variant="contactLink" href={phone.to}>
        {phone.title}
      </Link>
      <Link
        variant="contactLink"
        href={email.to}
        sx={{
          mt: ['0.89em', '0.8em', '1.09em', '1em', '0.91em', '1em']
        }}>
        {email.title}
      </Link>
    </Box>
  );
};

const NavigationLinks = ({ links }) => {
  return (
    <ListWrapper
      animateLine={false}
      sx={{
        mt: ['30.10%', '20.16%', '13.58%', 0, 0, 0],
        gridColumn: ['1/13', '1/13', '1/25', '13/25', '13/25', '12 / span 12']
      }}>
      {links.map((link) => (
        <NavLinkListItem
          key={link.title}
          title={link.title}
          to={link.to}
          type="navLinkFooter"
        />
      ))}
    </ListWrapper>
  );
};

const TextBlockWithLogo = ({ text }) => {
  return (
    <Box
      className="footer__text-block-with-logo"
      sx={{
        gridColumn: [
          '1/ span 11',
          '1 / span 8',
          '1 / span 12',
          '1 / span 10',
          '1 / span 8',
          '2 / span 6'
        ],
        gridRow: [null, null, null, 1, 1, 1],
        mt: ['32.88%', '30.53%', '27.64%', 0, 0, 0],
        alignSelf: [null, null, null, 'end', 'end', 'end']
      }}>
      <MdlExpoLogo
        sx={{
          width: ['44%', '46.3%', '46.3%', '44.3%', '48.6%', '54.6%']
        }}
      />
      <Paragraph
        type="p-100-150"
        sx={{
          mt: ['1.33em', '1.85em', '1.85em', '1.33em', '1.71em', '1.85em']
        }}>
        {text}
      </Paragraph>
    </Box>
  );
};

const LegalAndPrivacy = ({ legal, privacy }) => {
  return (
    <FlexWrapper
      className="footer__legal-and-privacy"
      sx={{
        alignSelf: 'end',
        gridColumn: [
          '1 / span 10',
          '1 /span 7',
          '1 / span 10',
          '13 / span 6',
          '13 / span 8',
          '12 / span 5'
        ],
        gridRow: [null, null, null, null, null, 5],
        flexDirection: ['row', 'row', 'row', 'row', 'row', 'row'],
        mt: ['7%', '10%', '7%', '8%', '7%', 0]
      }}>
      <NavigationLink
        variant="legalLink"
        to={legal.to}
        sx={{
          mr: ['14.7%', '18.8%', '15.6%', '13.6%', '13.6%', '15%']
        }}>
        {legal.title}
      </NavigationLink>
      <NavigationLink variant="legalLink" to={privacy.to}>
        {privacy.title}
      </NavigationLink>
    </FlexWrapper>
  );
};

const CopyrightText = ({ text }) => {
  return (
    <Paragraph
      type="p-100-150"
      sx={{
        gridColumn: [
          '1 / 13',
          '1 / span 7',
          '1 /span 12',
          '1 / span 10',
          '1 / span 8',
          '2 / span 6'
        ],
        gridRow: [null, null, null, 6, 6, 5],
        alignSelf: 'end',
        mt: ['7.52%', '6.74%', '5.18%', 0, 0, 0]
      }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

const Spacer = ({ position }) => {
  // use of spacer when only it's needed
  const styles = [
    {
      mt: [0, 0, 0, '5.81%', '7.01%', '6.25%'],
      display: ['none', 'none', 'none', 'block', 'block', 'block']
    },
    {
      mt: [0, '10.86%', '7.92%', '9.44%', '6.41%', '5.63%'],
      display: ['none', 'block', 'block', 'block', 'block', 'block']
    },
    {
      mt: ['12.54%', '10.86%', '7.92%', '5.81%', '8.21%', '5.00%']
    }
  ];
  return <GridItem className="spacer" sx={styles[position]} />;
};

const NavLinksSpcer = () => (
  <GridItem
    className="footer__headline-spacer"
    sx={{
      mt: [0, 0, 0, '5.81%', '7.01%', '6.25%'],
      display: ['none', 'none', 'none', 'block', 'block', 'block']
    }}
  />
);

const LegalSpacer = () => (
  <GridItem
    className="footer__headline-spacer"
    sx={{
      mt: [0, '10.86%', '7.92%', '9.44%', '6.41%', '5.63%'],
      display: ['none', 'block', 'block', 'block', 'block', 'block']
    }}
  />
);

const BottomSpacer = () => (
  <GridItem
    className="footer__headline-spacer"
    sx={{
      mt: ['40%', '30%', '20%', '15%', '8.21%', '5%']
    }}
  />
);

const Logos = ({ logos }) => (
  <FlexWrapper
    className="footer_logos-wrapper"
    sx={{
      alignItems: 'center',
      gridColumn: [
        '1/13',
        '1/span 10',
        '1/span 12',
        '13/span 10',
        '13/span 10',
        '17/span 7'
      ],
      gap: ['3%', '5.5%', '5.5%', '5.5%', '7%', '6%'],
      justifyContent: [
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-end'
      ],
      mt: ['24.16%', 0, 0, 0, 0, 0],
      ml: '-2%' // align first logo with grid since image has paddings
    }}>
    {logos.map((logo) => {
      const height = logo.src.childImageSharp.gatsbyImageData.height;
      const width = logo.src.childImageSharp.gatsbyImageData.width;

      return (
        <ImageContainer
          key={logo.alt}
          {...logo}
          sx={{
            aspectRatio: `${width}/${height}`,
            width: '35%'
          }}
        />
      );
    })}
  </FlexWrapper>
);
