import React from 'react';

// Create Context
export const SiteLoadContext = React.createContext();

// Hook
export const useSiteLoad = () => React.useContext(SiteLoadContext);

// Create ContextProvider
export const SiteLoadContextProvider = ({ children }) => {
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);

  return (
    <SiteLoadContext.Provider
      value={{
        isFirstLoad,
        setIsFirstLoad
      }}>
      {children}
    </SiteLoadContext.Provider>
  );
};
