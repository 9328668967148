import React from 'react';

// External Components
import { Heading } from '@thepuzzlers/pieces';

export const ListText = ({ children, sx, ...props }) => {
  return (
    <Heading as="h3" type="h3-300-110" sx={sx} {...props}>
      {children}
    </Heading>
  );
};
