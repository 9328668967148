export const typographyConfig = {
  // h3, h4 and paragraph are left in for the legal pages
  h3: {
    fontSizes: [33.6, 28.8, 37.6, 37.6, 44],
    fontFamily: 'primaryBold',
    lineHeight: 1.25
  },
  h4: {
    fontSizes: [19.5, 24.5, 25.5, 18, 26.5, 28.5],
    fontFamily: 'primaryBold',
    lineHeight: 1.25
  },
  h5: {
    fontSizes: [16, 20, 22, 14, 22, 24],
    fontFamily: 'primaryBold',
    lineHeight: 1.25
  },
  paragraph: {
    fontSizes: [14.97, 15.29, 16.27, 14.1, 15.78, 15.82],
    fontFamily: 'body',
    lineHeight: 1.75
  },

  // headings
  'h1-100-150': {
    fontSizes: [11.23, 13.49, 15.31, 12.22, 14.85, 17.8],
    fontFamily: 'primary',
    lineHeight: 1.5
  },
  'h1-500-115': {
    fontSizes: [22.46, 30.58, 38.29, 26.32, 37.12, 47.46],
    fontFamily: 'primary',
    lineHeight: 1.15
  },
  'h1-900-100': {
    fontSizes: [27, 42, 70, 50, 76.1, 87.01],
    fontFamily: 'primary',
    lineHeight: 1
  },
  'h2-100-150': {
    fontSizes: [16.84, 19.78, 21.06, 16.92, 22.27, 27.68],
    fontFamily: 'primary',
    lineHeight: 1.5,
    '& > span': {
      fontFamily: 'primary.bold'
    }
  },
  'h2-200-110': {
    fontSizes: [28.07, 34.17, 40.2, 30.08, 44.54, 55.37],
    fontFamily: 'primary',
    lineHeight: 1.1
  },
  'h2-300-110': {
    fontSizes: [29.94, 43.17, 53.6, 37.59, 51.97, 63.28],
    fontFamily: 'primary',
    lineHeight: 1.1
  },
  'h2-500-100': {
    fontSizes: [33.68, 43.17, 53.6, 37.59, 59.39, 71.19],
    fontFamily: 'primary',
    lineHeight: 1
  },
  'h2-900-110': {
    fontSizes: [35.56, 50.36, 68.91, 37.59, 53.82, 63.28],
    fontFamily: 'primary',
    lineHeight: 1.1
  },
  'h3-300-100': {
    fontSizes: [16.84, 17.99, 21.06, 15.04, 22.27, 23.73],
    fontFamily: 'primary',
    lineHeight: 1
  },
  'h3-300-110': {
    fontSizes: [16.84, 17.99, 21.06, 15.04, 20.42, 23.73],
    fontFamily: 'primary',
    lineHeight: 1.1
  },
  'h3-500-100': {
    fontSizes: [22.46, 23.38, 28.71, 22.56, 27.84, 37.57],
    fontFamily: 'primary',
    lineHeight: 1
  },
  'h3-700-110': {
    fontSizes: [29.94, 35.97, 34.46, 30.08, 40.83, 55.37],
    fontFamily: 'primary',
    lineHeight: 1.1
  },
  'h4-500-125': {
    fontSizes: [28.07, 30.58, 34.46, 30.08, 27.84, 33.62],
    fontFamily: 'primary',
    lineHeight: 1.25
  },

  xl: {
    fontSizes: [112.28, 143.89, 153.14, 112.78, 176.32, 237.29],
    fontFamily: 'primary',
    lineHeight: 1
  },

  'header-xl': {
    fontSizes: [56.5, 87.7, 128, 122.5, 175.6, 237.29],
    fontFamily: 'primary',
    lineHeight: 1
  },

  // paragraphs
  'p-100-150': {
    fontSizes: [11.23, 11.69, 12.44, 11.28, 12.99, 12.85],
    fontFamily: 'body',
    lineHeight: 1.5,
    '& > span': {
      fontFamily: 'body.bold'
    }
  },
  'p-300-150': {
    fontSizes: [12.16, 13.49, 14.36, 12.22, 14.85, 12.85],
    fontFamily: 'body',
    lineHeight: 1.5,
    '& > span': {
      fontFamily: 'body.bold'
    }
  },
  'p-300-150-a': {
    fontSizes: [12.16, 13.49, 15.31, 13.16, 12.99, 13.84],
    fontFamily: 'body',
    lineHeight: 1.5
  },
  'p-300-150-b': {
    fontSizes: [14.04, 14.39, 17.23, 15.04, 14.85, 15.82],
    fontFamily: 'body',
    lineHeight: 1.5
  },
  'p-500-175': {
    fontSizes: [12.16, 13.49, 15.31, 13.16, 14.85, 17.8],
    fontFamily: 'body',
    lineHeight: 1.75,
    '& > span': {
      fontFamily: 'body.bold'
    }
  },
  'p-600-150': {
    fontSizes: [14.97, 16.19, 19.14, 18.8, 22.27, 17.8],
    fontFamily: 'body',
    lineHeight: 1.5
  },
  'p-700-175': {
    fontSizes: [13.1, 15.29, 17.23, 13.16, 16.7, 19.77],
    fontFamily: 'body',
    lineHeight: 1.75,
    '& > span': {
      fontFamily: 'body.bold'
    }
  },
  'p-700-100': {
    fontSizes: [43.04, 71.04, 91.88, 45.11, 70.53, 87.01],
    fontFamily: 'body',
    lineHeight: [1, 1, 1.1, 1, 1, 1]
  },
  'p-1000-100': {
    fontSizes: [44.91, 71.94, 105.28, 90.23, 111.36, 138.42],
    fontFamily: 'body',
    lineHeight: 1
  },
  'p-1000-200': {
    fontSizes: [17, 19.78, 23, 21, 22, 25],
    fontFamily: 'body',
    lineHeight: 1
  },
  'slider-number': {
    fontSizes: [28.07, 30.58, 34.46, 30.08, 31.55, 33.62],
    fontFamily: 'body',
    lineHeight: 1.25
  },

  // buttons and links
  hyperLink: {
    fontSizes: [18.71, 19.78, 21.06, 18.8, 20.42, 23.73],
    fontFamily: 'body',
    lineHeight: 1.1
  },

  navbarLink: {
    fontSizes: [9.36, 10.79, 11.49, 9.4, 11.14, 11.86],
    fontFamily: 'body',
    lineHeight: 1
  },

  navLinkFooter: {
    fontSizes: [14.97, 16.19, 17.23, 14.1, 14.85, 17.8],
    fontFamily: 'body',
    lineHeight: 1
  },
  responsiveReference: {
    // all fontSizes are 16px
    fontSizes: [14.97, 14.39, 15.31, 15.04, 14.85, 15.82],
    fontFamily: 'body',
    lineHeight: 1
  }
};
