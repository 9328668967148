import React from 'react';

// External components
import { GridWrapper, FlexWrapper, Link } from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';
import { useLocation } from '@reach/router';

// Local Components
import { useNavigationData } from './useNavigationData';
import { LanguageSwitch, Underline } from 'components';

// Self Components
import { NavigationOverlay } from './NavigationOverlay';
import { Logo, NavButton } from './components';

// Helper function
import { usePathMatch } from './helper';

// Animations
import {
  revealNavigation,
  onScrollNavigationBarAnimation
} from './components/animation';

export const Navigation = () => {
  const { name, phone, navigationLinks, email } = useNavigationData();

  const navigationRef = React.useRef(null);
  const { pathname } = useLocation();

  // Overlay functionality
  const [openOverlay, setOpenOverlay] = React.useState(false);

  const handleOpen = () => {
    setOpenOverlay(true);
  };

  const handleClose = () => {
    setOpenOverlay(false);
  };

  const overlayControl = { openOverlay, handleOpen, handleClose };

  // Animations
  React.useEffect(() => {
    // Get elements to animate
    const elements = [...navigationRef.current.children];

    // We use different arrays because otherwise in mobile the middle
    // elements will be animated anyway even when they are visible
    // making the last element show too late
    const staggerSmallerDevice = [elements[0], elements[3]];
    const staggerBiggerDevice = [
      elements[0],
      ...elements[1].children,
      ...elements[2].children,
      elements[3]
    ];

    const staggerElements =
      window.innerWidth < 749 ? staggerSmallerDevice : staggerBiggerDevice;
    const delay = pathname === '/' ? 4.6 : 0; // on first load if it is the landing page adjust to the header animation, otherwise it will animate right away

    const killRevealNavigation = revealNavigation(staggerElements, delay);
    const killOnScrollNavigation = onScrollNavigationBarAnimation();

    return () => {
      if (killOnScrollNavigation) killOnScrollNavigation();
      if (killRevealNavigation) killRevealNavigation();
    };
  }, []);

  return (
    // Markup
    <NavWrapper designatedRef={navigationRef}>
      <NavLogo />
      {/* <InfoWrapper phone={phone} email={email} /> */}
      <NavigationLinks navigationLinks={navigationLinks} />

      <LangSwitcher />
      <MenuButton text={name} handleClick={handleOpen} />
      <NavigationOverlay overlayControl={overlayControl} />
    </NavWrapper>
  );
};

// Elements

const NavWrapper = ({ children, designatedRef }) => (
  <GridWrapper
    className="navigation-bar-wrapper"
    as="nav"
    ref={designatedRef}
    sx={{
      position: 'fixed',
      py: ['3.14%', '3.49%', '3.96%', '1.45%', '2.80%', '2%'],
      width: '100%',
      zIndex: 10,
      bg: 'background'
    }}>
    {children}
  </GridWrapper>
);

const NavLogo = () => (
  <Logo
    sx={{
      // Animation value
      visibility: 'hidden'
    }}
  />
);

const LangSwitcher = () => (
  <LanguageSwitch
    sx={{
      alignSelf: 'center',
      alignItems: 'center',
      display: ['none', 'none', 'flex', 'flex', 'flex', 'flex'],
      gridColumn: [null, null, '15/span 5', '16/span 5', '20/25', '21/25'],
      justifySelf: [null, null, 'end', 'end', 'end', 'end']
    }}
  />
);

const MenuButton = ({ text, handleClick }) => (
  <NavButton
    text={text}
    handleClick={handleClick}
    sx={{
      // Animation value
      visibility: 'hidden'
    }}
  />
);

const NavigationLinks = ({ navigationLinks }) => {
  return (
    <FlexWrapper
      className="navigation-links-wrapper"
      sx={{
        display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
        alignItems: 'center',
        justifyContent: [null, null, null, null, 'center', 'center'],
        justifySelf: 'center',
        gridColumn: [null, null, null, null, '7 / span 13', '5 / span 16'],
        gap: [null, null, null, null, '2.5rem', '3rem']
      }}>
      {navigationLinks.map((link, index) => (
        <NavigationLinkItem key={index} data={link} />
      ))}
    </FlexWrapper>
  );
};

const NavigationLinkItem = ({ data: { title, to } }) => {
  const { isPathMatch } = usePathMatch(to);
  return (
    <NavigationLink
      to={to}
      variant="navBarLink"
      sx={{
        textTransform: 'uppercase',
        position: 'relative',
        ':hover .navigation-link-item__underline': {
          transform: 'scaleX(1)'
        },
        // Animation value
        visibility: 'hidden'
      }}>
      {title}
      <Underline
        isPathMatch={isPathMatch}
        className="navigation-link-item__underline"
        sx={{
          display: 'block'
        }}
      />
    </NavigationLink>
  );
};
