// this helper function remove th p tag and change strong tag to be span tag
export const convertApiHtmlText = (htmlText) => {
  if (!htmlText) return '';

  const replacedDoublePsByPTag = htmlText.replace(
    /<\/p><p><\/p><p>/g,
    '<br/><br/>'
  );

  const replacedPsByPTag = replacedDoublePsByPTag.replace(
    /\<\/p\>\<p\>/g,
    '<br/>'
  );

  // remove the p tag
  const removedFrontPTag = replacedPsByPTag.replace(/\<p\>/g, '');
  const removedBackPTag = removedFrontPTag.replace(/\<\/p\>/g, '');

  // change strong tag to be span tag
  const switchedStrongTag = removedBackPTag.replace(/strong/g, 'span');

  return switchedStrongTag;
};
