import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const BackArrow = ({ sx, ...props }) => (
  <Box
    as="svg"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={sx}
    {...props}>
    <path
      d="M16.7025 9.57296L14.1709 7.04135L-0.000314236 21.2126L14.2 35.4129L16.7607 32.8522L7.01256 23.104L40.5347 23.104L40.5638 19.3502L6.92526 19.3502L16.7025 9.57296Z"
      fill="currentColor"
    />
  </Box>
);
