import React from 'react';

// External Component
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';
import { FlexWrapper, Heading, Box } from '@thepuzzlers/pieces';

// Local Components
import { RedArrow } from 'components';

// Main Components
export const NavLinkListItem = ({
  title,
  to,
  type,
  handleOverlayClose = () => {}
}) => {
  return (
    // We need to set a variant here to apply a font size to the navigation link of 16px
    // We need that font size to calculate padding y of the flexwrapper correctly and the width of the arrowIcon

    <NavigationLink
      to={to}
      sx={{
        variant: 'typography.responsiveReference.normal'
      }}>
      {/** the outer box needed by animation purposes */}
      <Box
        sx={{
          overflow: 'hidden'
        }}>
        <FlexWrapper
          onClick={handleOverlayClose}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            py: ['1.25em', '1.5em', '2em', '1.12em', '2em', '2em']
          }}
          // Animation
          initial="initial"
          whileHover="hover"
          className="nav-link__content-container">
          <Heading type={type} variant="bold" as="span">
            {title}
          </Heading>
          <RedArrow
            sx={{
              width: [
                '1.25em',
                '1.25em',
                '1.12em',
                '1.12em',
                '1.12em',
                '1.25em'
              ]
            }}
          />
        </FlexWrapper>
      </Box>
    </NavigationLink>
  );
};
