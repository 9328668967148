import React from 'react';
import PropTypes from 'prop-types';
import { CoreLayout } from 'gatsby-theme-thepuzzlers-core';
import { Box } from '@thepuzzlers/pieces';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Draggable } from 'gsap/Draggable';
import useVH from 'react-vh';

// Local Components
import { Footer, Navigation } from 'sections';
import { SectionSpacer } from 'components';

// Hooks
import { useScrollTriggerRefresh } from 'hooks/useScrollTriggerRefresh';

import 'gatsby-plugin-theme-ui/fonts/fontImports';

// Custom Context
import {
  NavOverlayAnimationContextProvider,
  SiteLoadContextProvider
} from 'context';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(Draggable);
}

const Layout = ({ children, pageContext }) => {
  useScrollTriggerRefresh();

  useVH();

  const path = pageContext.intl.originalPath;

  // Check if user has been to a different path before
  // (for back button functionality of project detail page)
  React.useEffect(() => {
    if (!path.match(/\/projects\/\w+/)) {
      sessionStorage.setItem('current-path', JSON.stringify(path));
    }
  }, [path]);

  // set current path to null when user is leaving the page - in case they come back again later
  React.useEffect(() => {
    return () => {
      sessionStorage.setItem('current-path', null);
    };
  }, []);

  return (
    // CoreLayout includes PageContextProvider & Global component
    <CoreLayout pageContext={pageContext}>
      <NavOverlayAnimationContextProvider>
        <SiteLoadContextProvider>
          <Navigation />
          <Box
            as="main"
            sx={{
              position: 'relative',
              overflowX: 'hidden'
            }}>
            {/* This spacer holds the height of the Navigation */}
            {pageContext.intl.originalPath !== '/projectsDetail/' && (
              <SectionSpacer
                spacing={[
                  '15.05%',
                  '13.96%',
                  '12.44%',
                  '6.97%',
                  '8.81%',
                  '7.50%'
                ]}
              />
            )}
            {children}
          </Box>
          <Footer />
        </SiteLoadContextProvider>
      </NavOverlayAnimationContextProvider>
    </CoreLayout>
  );
};

Layout.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Layout;
