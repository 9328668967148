export const fontFamilies = {
  body: {
    normal: 'Sohne Breit, sans-serif',
    bold: 'Sohne Breit Bold, sans-serif'
  },
  primary: {
    normal: 'Sohne Breit, sans-serif',
    bold: 'Sohne Breit Bold, sans-serif'
  },
  primaryBold: {
    normal: 'Sohne Breit Bold, sans-serif'
  }
};
