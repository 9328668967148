import React from 'react';

// External components
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { MdlExpoLogo } from 'components';

// Assets

export const Logo = ({ onClick, sx }) => (
  <NavigationLink
    to="/"
    sx={{
      // this remove weird space of the span
      display: 'flex',
      alignItems: 'center',
      // this remove weird space of the a tag
      '& a': {
        display: 'flex',
        alignItems: 'center',
        width: '100%'
      },
      gridColumn: [
        '1/span 5',
        '1/span 4',
        '1/span 6',
        '1/span 5',
        '1/span 4',
        '1/span 4'
      ],
      width: ['100%', '95.4%', '95.6%', '90.4%', '100%', '80%'],
      ...sx
    }}>
    <MdlExpoLogo
      onClick={onClick}
      sx={{
        width: '100%'
      }}
    />
  </NavigationLink>
);
