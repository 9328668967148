// No nesting! - colors declarations can only be one level deep
// No variables - you can't reference the colors by var name (e.g. 'primary') within this file

const base = {
  background: '#F5F3EF',
  navy: '#090042',
  red: '#C00B27',
  dark: '#222222', // text-primary in figma
  light: '#F8F8F8', // white in figma
  gray: '#D4D4D4'
};

const textColors = {
  text: base.dark,
  heading: base.dark
};

export const colors = {
  // defaults
  ...base,
  ...textColors
};
