import React from 'react';

// Local Components
import { Vector } from 'components';

// Assets
import arrowIcon from 'assets/svg/arrow.svg';

// Animation
import { arrowAnimation, arrowTransition } from './animation';

export const RedArrow = ({ sx }) => {
  return (
    <Vector
      src={arrowIcon}
      variants={arrowAnimation}
      transition={arrowTransition}
      style={{ originX: 0.5, originY: 0.5 }}
      sx={{
        // animation depedencies for red arrow
        mr: ['0.3em', '0.3em', '0.2em', '0.3em', '0.38em', '0.35em'],
        ...sx
      }}
    />
  );
};
