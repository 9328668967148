import React from 'react';

// External component
import { Heading } from '@thepuzzlers/pieces';

export const ListTitle = ({ text, ...props }) => {
  return (
    <Heading
      as="h2"
      type="h2-200-110"
      variant="bold"
      sx={{
        gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/span 22', '1/span 23'],
        textTransform: 'uppercase'
      }}
      {...props}>
      {text}
    </Heading>
  );
};
