import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';
import { gsap } from 'gsap';

// Local Components
import { ImageContainer } from './ImageContainer';

// animations
import { revealImageFromCover } from 'sections/animations';

export const AnimatedImage = ({ className, src, alt, sx }) => {
  const wrapperRef = React.useRef(null);

  React.useEffect(() => {
    const image = wrapperRef.current.children[0];
    const cover = wrapperRef.current.children[1];

    let tl = gsap.timeline({
      defaults: { ease: 'Power3.easeOut' },
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: 'top 80%'
      }
    });

    tl.add(revealImageFromCover(image, cover));

    const killAnimation = () => {
      tl.scrollTrigger.kill(true);
      tl.pause(0).kill(true);
    };

    return killAnimation;
  }, []);

  return (
    <Box
      ref={wrapperRef}
      sx={{ position: 'relative', overflow: 'hidden', ...sx }}>
      <ImageContainer
        className={className}
        src={src}
        alt={alt}
        sx={{ height: '100%', width: '100%' }}
      />
      <ImageCover />
    </Box>
  );
};

export const ImageCover = ({ sx, ...props }) => (
  <Box
    className="animated-image__cover"
    sx={{
      bg: 'background',
      position: 'absolute',
      top: '-1%',
      left: '-1%',
      height: '102%',
      width: '102%',
      zIndex: 1,
      ...sx
    }}
    {...props}
  />
);
