import { getFluidTypographyStyles } from '@thepuzzlers/pieces';
import { merge } from 'theme-ui';
// client imports
import { fontFamilies } from './fonts/fontFamilies';
import { typographyConfig } from './typographyConfig';

const fluidTypography = getFluidTypographyStyles({
  typographyConfig,
  fontFamilies
});

// Overwrite styles that are specific to bold or normal variants here:
const typography = merge(fluidTypography, {
  // h1: {
  //   bold: {
  //     color: 'primary',
  //   },
  // normal: {
  //   color: 'primary',
  // },
  // },
  // primary: {
  // color: 'primary'
  // }
});

export { typography };
