import React from 'react';

// External components
import { Section } from '@thepuzzlers/pieces';

// Local Components
import { HeadlineBlock, SectionSpacer } from '.';

export const HeadlineSection = ({ id, text, isFullHeight = false }) => {
  return (
    <>
      <Section
        id={id}
        sx={
          isFullHeight && {
            height: [
              'calc(86 * var(--vh, 1vh))',
              'calc(84 * var(--vh, 1vh))',
              'calc(82 * var(--vh, 1vh))',
              'calc(82 * var(--vh, 1vh))',
              'calc(80 * var(--vh, 1vh))',
              'calc(80 * var(--vh, 1vh))'
            ],
            alignContent: 'center'
          }
        }>
        <HeadlineBlock
          text={text}
          initial={{ y: 80, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            duration: 1
          }}
        />
      </Section>
      {/* this spacer compensate the navbar height so the top can be center */}
      {isFullHeight && <SectionSpacer spacing={'calc(12 * var(--vh, 1vh))'} />}
    </>
  );
};
