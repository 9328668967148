import React from 'react';

// External Components
import { FlexWrapper, Image } from '@thepuzzlers/pieces';

export const Vector = ({ designatedRef, src, alt = null, ...props }) => {
  return (
    <FlexWrapper ref={designatedRef} {...props}>
      <Image src={src} alt={alt} sx={{ height: '100%', width: '100%' }} />
    </FlexWrapper>
  );
};
