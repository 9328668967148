import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';
import { gsap } from 'gsap';

export const VerticalLine = ({ sx, animateLine = true, ...props }) => {
  const lineRef = React.useRef(null);

  React.useEffect(() => {
    if (animateLine) {
      gsap.from(lineRef.current, {
        scaleY: 0,
        transformOrigin: 'top center',
        duration: 2,
        scrollTrigger: {
          trigger: lineRef.current,
          start: 'top 80%'
        }
      });
    }
  }, []);

  return (
    <Box
      ref={lineRef}
      className="vertical-line"
      sx={{ bg: 'red', height: '100%', width: '2px', ...sx }}
      {...props}
    />
  );
};

export const HorizontalLine = ({ sx, animateLine = true, ...props }) => {
  const lineRef = React.useRef(null);

  React.useEffect(() => {
    if (animateLine) {
      gsap.from(lineRef.current, {
        scaleX: 0,
        transformOrigin: 'left center',
        duration: 2,
        scrollTrigger: {
          trigger: lineRef.current,
          start: 'top 80%'
        }
      });
    }
  }, []);

  return (
    <Box
      ref={lineRef}
      className="horizontal-line"
      sx={{ bg: 'red', height: '2px', width: '100%', ...sx }}
      {...props}
    />
  );
};
