import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const Underline = ({ sx, isPathMatch, ...props }) => {
  return (
    <Box
      sx={{
        bg: 'red',
        bottom: ['-88%', '-52%', '-88%', '-95%', '-90%', '-88%'],
        height: '2px',
        left: 0,
        position: 'absolute',
        width: '100%',
        transition: 'transform 0.5s ease',
        transformOrigin: 'left',
        transform: isPathMatch ? 'scaleX(1)' : 'scaleX(0)',
        ...sx
      }}
      {...props}
    />
  );
};
