exports.components = {
  "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-legal-js": () => import("./../../../node_modules/gatsby-theme-thepuzzlers-legal-pages/src/pages/legal.js" /* webpackChunkName: "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-legal-js" */),
  "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-privacy-policy-js": () => import("./../../../node_modules/gatsby-theme-thepuzzlers-legal-pages/src/pages/privacy-policy.js" /* webpackChunkName: "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-privacy-policy-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-project-detail-project-detail-js": () => import("./../../../src/templates/projectDetail/ProjectDetail.js" /* webpackChunkName: "component---src-templates-project-detail-project-detail-js" */)
}

