import { useStaticQuery, graphql } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export function useFooterData() {
  const { locale } = usePageContext();
  const data = useStaticQuery(graphql`
    query {
      en: footerDataJson {
        headline
        paragraph
        copyright
        logos {
          src {
            childImageSharp {
              gatsbyImageData(quality: 100, backgroundColor: "#F5F3EF")
            }
          }
          alt
        }
      }
      de: deFooterDataJson {
        headline
        paragraph
        copyright
        logos {
          src {
            childImageSharp {
              gatsbyImageData(quality: 100, backgroundColor: "#F5F3EF")
            }
          }
          alt
        }
      }
    }
  `);

  return data[locale];
}
