import React from 'react';

// External Components
import { Paragraph, Section } from '@thepuzzlers/pieces';

// Helper Function
import { convertApiHtmlText } from 'sections/helper/convertApiHtmlText';

export const ParagraphSection = ({ text, cmsData, ...props }) => {
  return (
    <Section {...props}>
      <Paragraph
        type={'p-500-175'}
        dangerouslySetInnerHTML={{
          __html: text || convertApiHtmlText(cmsData?.description?.html)
        }}
        sx={{
          gridColumn: [
            '1 / 13',
            '2 / span 10',
            '4 / span 18',
            '8 / span 15',
            '8 / span 13',
            '10 / span 12'
          ]
        }}
      />
    </Section>
  );
};
