import React, { Children } from 'react';

import { Box } from '@thepuzzlers/pieces';
import { HorizontalLine } from './Lines';

export const ListWrapper = ({ children, animateLine, sx }) => {
  const arrayChildren = Children.toArray(children);

  return (
    <Box
      className="list-wrapepr"
      sx={{
        gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/ span 23'],
        mt: ['12.54%', '9.31%', '6.79%', '4.65%', '5.61%', '5.22%'],
        ...sx
      }}>
      {arrayChildren.map((listItem, index) => (
        <React.Fragment key={`list-wrapper__item-${index}`}>
          {index === 0 && <HorizontalLine animateLine={animateLine} />}
          {listItem}
          <HorizontalLine animateLine={animateLine} />
        </React.Fragment>
      ))}
    </Box>
  );
};
