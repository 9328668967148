const linkDefault = {
  cursor: 'pointer'
};

const buttonDefault = {
  cursor: 'pointer',
  transition: '0.2s ease-out'
};

const backDefault = {
  ...buttonDefault,
  color: 'text',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  textTransform: 'uppercase'
};

const links = {
  defaults: {
    ...linkDefault
  },
  hyperLink: {
    ...linkDefault,
    p: 0,
    textAlign: 'left',
    textTransform: 'uppercase',
    variant: 'typography.hyperLink.bold',
    ':hover,:focus,active': {}
  },
  navigation: {
    ...linkDefault,
    variant: 'typography.navigationLink.normal',
    ':hover,:focus,&.active': {}
  },
  navOverlayContactLink: {
    ...linkDefault,
    variant: 'typography.p-300-150.bold'
  },
  contactLink: {
    ...linkDefault,
    variant: 'typography.h3-300-110.bold',
    ':hover,:focus,&.active': {}
  },
  navBarLink: {
    ...linkDefault,
    variant: 'typography.navbarLink.bold'
  },
  navLinkFooter: {
    ...linkDefault,
    variant: 'typography.navLinkFooter.bold'
  },
  legalLink: {
    ...linkDefault,
    variant: 'typography.p-100-150.normal'
  },
  iconLink: {
    ...linkDefault,
    p: 0,
    m: 0
  }
};

const buttons = {
  primary: {
    ...buttonDefault,
    variant: 'typography.buttonLarge.normal',
    '&:hover': {}
  },
  secondary: {
    ...buttonDefault,
    variant: 'typography.button.normal',
    '&:hover': {}
  },
  navButton: {
    ...buttonDefault,
    p: 0,
    textTransform: 'uppercase',
    variant: 'typography.navbarLink.bold',
    width: 'max-content'
  },
  back: {
    primary: {
      ...backDefault,
      variant: 'typography.p-500-175.bold'
    },
    secondary: {
      ...backDefault,
      variant: 'typography.hyperLink.bold'
    }
  }
};

const cards = {
  primary: {}
};

export { links, buttons, cards };
