import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';
import { colors } from 'gatsby-plugin-theme-ui/colors';

export const MdlExpoLogo = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      {...props}
      sx={sx}
      viewBox="0 0 160 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.305 7.367h-8.604v9.173h9.293c1.166 0 2.02-.237 2.558-.711.54-.476.81-1.22.81-2.233v-3.164c0-1.053-.33-1.828-.985-2.324-.655-.494-1.679-.741-3.072-.741zm1.678-4.429c2.744 0 4.739.481 5.986 1.443 1.248.961 1.872 2.492 1.872 4.592v6.18c0 1.995-.5 3.455-1.497 4.375-.998.92-2.584 1.381-4.756 1.381H55.221V2.94h15.762zM87.908 16.568h11.96v4.345H82.416V2.942h5.492v13.626zM104.809 14.577h9.434v-.242c0-.906-.232-1.53-.695-1.873-.465-.341-1.385-.51-2.762-.51h-2.52c-1.281 0-2.178.197-2.689.591-.513.397-.768 1.075-.768 2.034zm6.012-4.112c1.913 0 3.244.353 3.993 1.061.75.708 1.126 1.95 1.126 3.728 0 .212-.002.373-.005.482-.003.11-.009.21-.016.3l-11.112.01v.55c0 1.075.261 1.825.782 2.25.52.424 1.429.636 2.722.636h2.647c1.126 0 1.958-.14 2.495-.42.538-.28.807-.712.807-1.297v-.108h1.68v.26c0 .98-.392 1.722-1.174 2.226-.782.507-1.928.76-3.433.76h-3.04c-1.83 0-3.137-.339-3.926-1.015-.789-.676-1.181-1.8-1.181-3.37v-1.622c0-1.595.39-2.734 1.171-3.413.782-.68 2.094-1.018 3.936-1.018h2.528zM116.692 20.766l5.417-5.436-4.742-4.732h2.192l3.7 3.678 3.658-3.678h2.232l-4.723 4.616 5.591 5.552h-2.434l-4.324-4.51-4.269 4.51h-2.298zM136.798 11.985c-1.474 0-2.453.214-2.93.642-.481.428-.72 1.266-.72 2.515v1.207c0 1.217.242 2.037.728 2.457.488.422 1.46.632 2.922.632h2.259c1.468 0 2.444-.21 2.93-.632.487-.42.73-1.24.73-2.457v-1.207c0-1.249-.242-2.087-.725-2.515-.482-.428-1.461-.642-2.935-.642h-2.259zm-.062-1.505h2.733c1.751 0 3.001.342 3.748 1.028.747.686 1.12 1.839 1.12 3.461v1.602c0 1.553-.38 2.666-1.136 3.337-.754.674-2 1.01-3.732 1.01h-3.127c-.754 0-1.384-.114-1.894-.338a3.081 3.081 0 01-1.283-1.082V24.2h-1.564V10.615h1.381v1.661c.283-.65.7-1.112 1.25-1.386.551-.274 1.385-.41 2.504-.41z"
        fill="#222"
      />
      <mask
        id="a"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="146"
        y="10"
        width="14"
        height="11">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M146.769 10.465h13.228v10.438h-13.228V10.465z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152.045 19.404h2.645c1.482 0 2.464-.214 2.95-.647.486-.431.73-1.27.73-2.52v-1.206c0-1.21-.245-2.026-.734-2.448-.489-.422-1.471-.632-2.946-.632h-2.645c-1.468 0-2.444.21-2.929.632-.487.422-.732 1.237-.732 2.448v1.207c0 1.249.243 2.088.726 2.52.483.432 1.461.646 2.935.646zm-.41 1.499c-1.753 0-3-.343-3.747-1.029-.747-.686-1.119-1.84-1.119-3.461V14.82c0-1.559.378-2.674 1.134-3.347.756-.673 2.001-1.008 3.732-1.008h3.495c1.731 0 2.976.335 3.732 1.008.756.673 1.136 1.788 1.136 3.347v1.593c0 1.622-.376 2.775-1.122 3.461-.746.686-1.994 1.029-3.746 1.029h-3.495z"
          fill="#222"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20.897h17.972V2.927H6.738l-.421.013-.423.04-.417.065-.413.093-.407.118-.4.143-.388.17-.376.19-.364.216-.351.238-.335.26-.318.279-.299.3-.28.318-.258.335-.239.35-.215.364-.193.376-.168.39-.143.399-.119.406-.092.412-.065.419-.041.421L0 9.665v11.232z"
        fill="#C00B27"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.193 4.782a.385.385 0 00-.036-.167.301.301 0 00-.124-.121.48.48 0 00-.173-.057 1.524 1.524 0 00-.23-.014h-.391v.81h.333c.108 0 .202-.009.281-.026a.46.46 0 00.193-.085.372.372 0 00.114-.143.53.53 0 00.033-.197zm.929 1.712h-.585l-.866-.93h-.425v.93h-.432V4.097h.818c.164 0 .299.006.406.021a.966.966 0 01.315.102.62.62 0 01.241.216.6.6 0 01.077.319c0 .17-.048.31-.148.423a.996.996 0 01-.406.268l1.005 1.048zm.496-1.153c0-.541-.193-1.007-.576-1.394a1.875 1.875 0 00-1.386-.582c-.542 0-1.004.193-1.387.582a1.914 1.914 0 00-.575 1.394c0 .54.192 1.005.575 1.392.383.389.845.582 1.387.582.541 0 1.003-.193 1.386-.582.383-.387.576-.851.576-1.392zm.302 0c0 .626-.222 1.16-.664 1.603a2.185 2.185 0 01-1.601.663c-.626 0-1.16-.221-1.602-.663a2.182 2.182 0 01-.664-1.602c0-.625.221-1.16.664-1.602a2.18 2.18 0 011.602-.664c.624 0 1.158.22 1.601.664.442.442.664.977.664 1.602zM43.51 2.942L36.99 14.167 30.583 2.942h-8.099v17.97h5.335V8.108l7.361 12.806h3.584l7.425-12.806v12.806h5.312V2.942H43.51z"
        fill="#222"
      />
    </Box>
  );
};
