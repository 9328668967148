import React from 'react';
import { LanguageSwitchLink, useIntl } from 'gatsby-theme-thepuzzlers-intl';
import { GatsbyLink } from 'gatsby-theme-thepuzzlers-core';
import { AnimatePresence } from 'framer-motion';

import { useLocation } from '@reach/router';

// Pieces components
import { Box, FlexWrapper } from '@thepuzzlers/pieces';

// Local Components
import { Underline } from './Underline';

export const LanguageSwitch = ({ sx, handleOverlayClose = () => {} }) => {
  const { pathname } = useLocation();

  // sets visibility to hidden on first load so navigation can animate in nicely
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);

  React.useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  const isProjectDetailPath =
    pathname.match(/\/projects\/\w+/) || pathname.match(/\/projekte\/\w+/);

  return (
    <Box className="navigation__language-switch-wrapper" sx={{ ...sx }}>
      <AnimatePresence>
        {!isProjectDetailPath && (
          <>
            <SwitchLink
              sx={{ ml: '-10px' }}
              isInitialLoad={isInitialLoad}
              language="de"
              onClick={() => {
                if (handleOverlayClose) handleOverlayClose();
              }}>
              DE
            </SwitchLink>
            <SwitchLink
              sx={{ mr: '-10px' }}
              isInitialLoad={isInitialLoad}
              language="en"
              onClick={() => {
                if (handleOverlayClose) handleOverlayClose();
              }}>
              EN
            </SwitchLink>
          </>
        )}
      </AnimatePresence>
      {isProjectDetailPath && (
        <ProjectDetailLanguageSwitch
          handleOverlayClose={handleOverlayClose}
          isInitialLoad={isInitialLoad}
        />
      )}
    </Box>
  );
};

// the translated paths for the projects details pages can't be handled by the LanguageSwitchLink component
// for the german language we use /projekte/<project-id>
// for the english language we use /projects/<project-id>
// we therefore have to manually construct the german and english links
const ProjectDetailLanguageSwitch = ({ handleOverlayClose, isInitialLoad }) => {
  const { pathname } = useLocation();
  const { locale } = useIntl();

  let englishProjectDetailPath;
  let germanProjectDetailPath;

  if (pathname.match(/\/en\/projects\/\w+/)) {
    englishProjectDetailPath = pathname;
    germanProjectDetailPath =
      '/projekte' + pathname.slice('/en/projects'.length);
  }

  if (pathname.match(/\/projekte\/\w+/)) {
    englishProjectDetailPath =
      '/en/projects' + pathname.slice('/projekte'.length);
    germanProjectDetailPath = pathname;
  }

  // when switching the language on a project detail page, we have to inform the session storage
  // this makes sure the "back to overview" button works as expected
  function handleSwitch() {
    sessionStorage.setItem('language-has-switched-on-project-detail', true);
  }

  return (
    <>
      <ManualSwitchLink
        sx={{ ml: '-10px' }}
        isInitialLoad={isInitialLoad}
        className={locale === 'de' && 'active'}
        to={germanProjectDetailPath}
        onClick={() => {
          handleSwitch();
          if (handleOverlayClose) handleOverlayClose();
        }}>
        DE
      </ManualSwitchLink>
      <ManualSwitchLink
        sx={{ mr: '-10px' }}
        isInitialLoad={isInitialLoad}
        className={locale === 'en' && 'active'}
        to={englishProjectDetailPath}
        onClick={() => {
          handleSwitch();
          if (handleOverlayClose) handleOverlayClose();
        }}>
        EN
      </ManualSwitchLink>
    </>
  );
};

const ManualSwitchLink = ({ children, sx, to, isInitialLoad, ...props }) => (
  <FlexWrapper
    className="switch-link"
    sx={{
      position: 'relative',
      // sets visibility to hidden on first load so navigation can animate in nicely
      visibility: isInitialLoad ? 'hidden' : 'visible',
      ...sx
    }}>
    <GatsbyLink
      to={to}
      variant="buttons.navButton"
      sx={{
        padding: '10px',
        '&.active, &:hover': {
          '& .language-switch-link__underline': {
            transform: 'scaleX(1)'
          }
        }
      }}
      {...props}>
      {children}
      <Underline
        className="language-switch-link__underline"
        sx={{ width: '50%', mx: '25%' }}
      />
    </GatsbyLink>
  </FlexWrapper>
);
const SwitchLink = ({ children, language, sx, isInitialLoad, ...props }) => (
  <FlexWrapper
    key={`switch-${language}`}
    className="switch-link"
    sx={{
      position: 'relative',
      // sets visibility to hidden on first load so navigation can animate in nicely
      visibility: isInitialLoad ? 'hidden' : 'visible',
      ...sx
    }}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}>
    <LanguageSwitchLink
      variant="buttons.navButton"
      sx={{
        padding: '10px',
        '&.active, &:hover': {
          '& .language-switch-link__underline': {
            transform: 'scaleX(1)'
          }
        }
      }}
      language={language}
      {...props}>
      {children}
      <Underline
        className="language-switch-link__underline"
        sx={{ width: '50%', mx: '25%' }}
      />
    </LanguageSwitchLink>
  </FlexWrapper>
);
