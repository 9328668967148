import React from 'react';

// External Components
import { Box, fullWidthMinusMargins } from '@thepuzzlers/pieces';

export const VideoPlayer = ({ video, isVertical, sx }) => {
  // Additional box is needed to handle full width minus margin,

  return (
    <Box
      className="video-container"
      sx={{
        gridColumn: [
          '1/13',
          '1/13',
          '1/25',
          '2/span 22',
          '3/span 20',
          '3/span 20'
        ],
        mt: ['10.67%', '7.76%', '5.66%', '4.65%', '4.00%', '2.97%'],
        mx: [fullWidthMinusMargins[0], 0, 0, 0, 0, 0],
        overflow: 'hidden'
      }}>
      <Box
        className="vide-player"
        dangerouslySetInnerHTML={{ __html: video.html }}
        sx={{
          // Fallback for aspect Ratio
          paddingTop: [
            isVertical ? '130%' : '56.25%',
            isVertical ? '120%' : '56.25%',
            isVertical ? '100%' : '56.25%',
            '56.25%',
            '56.25%',
            '56.25%'
          ], // result of aspect ratio 16 /9
          position: 'relative',
          '& iframe': {
            width: '100%',
            // height: 'max-content',
            // aspectRatio: '16 / 9',

            // Aspect ratio fall back
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%'
          },

          ...sx
        }}
      />
    </Box>
  );
};
