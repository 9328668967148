import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { ImageContainer } from 'components';

export const ImageWithClipPath = ({
  className,
  src,
  alt,
  sx,
  customDelay,
  ...props
}) => (
  <Box
    className={className}
    // Animation values
    initial="initial"
    animate="animate"
    sx={{ position: 'relative', ...sx }}
    {...props}>
    <ImageContainer
      src={src}
      alt={alt}
      // Animation values
      variants={headerImageAnimation}
      custom={customDelay}
      sx={{
        height: '100%',
        width: '100%'
      }}
    />
  </Box>
);

// Animation variant: Remove cover clip path and scale back image

const headerImageAnimation = {
  initial: {
    scale: 1.1,
    clipPath: 'inset(0 0 100% 0)'
  },
  animate: (custom) => ({
    scale: 1,
    clipPath: 'inset(0 0 0% 0)',
    transition: {
      clipPath: { delay: custom, duration: 1.2 },
      scale: {
        delay: custom,
        duration: 2
      }
    }
  })
};
