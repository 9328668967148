import { useStaticQuery, graphql } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export function useNavigationData() {
  const { locale } = usePageContext();

  const data = useStaticQuery(graphql`
    query {
      en: navigationDataJson {
        name
        navigationLinks {
          title
          to
        }
        legal {
          title
          to
        }
        privacy {
          title
          to
        }
        phone {
          title
          to
        }
        email {
          title
          to
        }
      }
      de: deNavigationDataJson {
        name
        navigationLinks {
          title
          to
        }
        legal {
          title
          to
        }
        privacy {
          title
          to
        }
        phone {
          title
          to
        }
        email {
          title
          to
        }
      }
    }
  `);

  return data[locale];
}
