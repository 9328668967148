import React from 'react';

// External Components
import { Heading, Box } from '@thepuzzlers/pieces';

export const HeadlineBlock = React.forwardRef(({ text, sx, ...props }, ref) => {
  // the outer box is neede for animation purposes
  return (
    <Box
      ref={ref}
      className="headline-block-component"
      sx={{
        gridColumnStart: 1,
        gridColumnEnd: ['13', '13', '25', 'span 23', 'span 23', 'span 23'],
        ...sx
      }}
      {...props}>
      <Heading
        as="h1"
        type="h1-500-115"
        variant="bold"
        sx={{
          textTransform: 'uppercase'
        }}>
        {text}
      </Heading>
    </Box>
  );
});
